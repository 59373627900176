import { useContext } from "react";
import { getFacebookUserDetails } from "../api/lib/facebook";
import { UserContext } from "../provider/Profile";

function useAuth() {
  const { setUserData } = useContext(UserContext);

  function login() {
    // Read Access Token from Session Storage
    let token: string = localStorage.getItem("tabf") || "";

    // Check if AT is defined
    if (token) {
      getFacebookUserDetails(token)
        .then((res) => {
          if (res.status === 200) {
            let data = { ...res.data, accessToken: token };
            data.picture = { ...res.data.picture.data };
            setUserData(data);
            localStorage.setItem("tabf", token);
          }
        })
        .catch((err) => {
          console.log(err);
          localStorage.removeItem("tabf");
        });
    }
  }

  function logout() {
    localStorage.removeItem("tabf");
    setUserData(null);
  }

  return { login, logout };
}

export { useAuth };

const titles = {
  home: "Home | RandomeR",
  about: "About | RandomeR",
  contact: "Contact | RandomeR",
  admin: "Admin | RandomeR",
  privacy: "Privacy | RandomeR",
  notfound: "Not Found | RandomeR",
  subscription: "Subscription | RandomeR",
  instagram: "Instagram Comment Picker | RandomeR",
  facebook: "Facebook Comment Picker | RandomeR",
};

export default titles;

import React, { useContext } from 'react'
import { LoginSocialFacebook } from 'reactjs-social-login';
import Button from '../atoms/Button';
import { UserContext } from '../../provider/Profile';

function FBLogin() {

    const { setUserData } = useContext(UserContext);

    return (
        <LoginSocialFacebook
            appId='1355230801924985'
            scope='pages_show_list,instagram_basic,pages_read_engagement,public_profile'
            onResolve={(res: any) => {
                localStorage.setItem('tabf', res.data.accessToken);
                setUserData(res.data);
            }}
            onReject={(err) => { console.log(err); }}
        >
            <Button text='fb login' />
        </LoginSocialFacebook>
    )
}



export default FBLogin
import React from 'react'
import styled from 'styled-components'
import Button from '../atoms/Button';
import { useAuth } from '../../hooks/useAuth';


function Logout() {

    const { logout } = useAuth();

    return (
        <Wrapper onClick={() => logout()}>
            <Button text='logout' ></Button >
        </Wrapper>
    )
}

const Wrapper = styled.div`
    
`

export default Logout